$lightGrey: #ececec;
$grey: #ddd;
$darkGrey: #969696;

$corporateColor: #aa113f;
$dangerColor: #dd4b39;
$yellowBackground: #f39c12;


// --- markdown editor -----

.CodeMirror {
  min-height: 80px;
  &-scroll {
    min-height: 80px!important;
  }
}

.direct-chat-messages {
  .editor-statusbar {
    position: absolute;
    right: 5px;
  }

  table {
    width: 100%;
    background-color: white;

    tr {
      border-bottom: 1px solid #f2f2f2;
      &:first-child {
        border-top: 1px solid #f2f2f2;
      }


      td, th {
        padding: 5px 20px;
      }
    }
  }

}

// --- \\ markdown editor -----

table.table {
  tr {
    th {
      a {
        text-decoration: none;
        color: #4b4b4b;
        border-bottom: 1px dashed #4b4b4b;
      }
    }
  }
}

table.num-change {
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  tr {
    td {
      border: 1px solid $grey;
      padding: 4px 12px;
      font-size: 120%;

      &:first-child, &:last-child {
        font-size: 100%;
        background-color: $lightGrey;
        font-weight: normal;
        padding: 4px 10px;
        cursor: pointer;
      }
    }
  }
}

#floatOrder {
  position: fixed;
  bottom: 0px;
  margin-bottom: 0px;
  z-index: 2000;
  right: 10px;
  width: 590px;
  display: none;
  border-left: 3px solid #d2d6de;
  border-right: 3px solid #d2d6de;
}

.universal-history {
  ul {
    li {
      padding: 10px 0;
      border-top: 1px dashed #bbbbbb;
    }
  }

  h4 {
    margin: 0px 0 5px 0;
  }
}

.num-change {
  input {
    border: 0px;
    width: 40px;
    text-align: center;
  }

  .second-item {
    padding: 1px;
  }

  &.num-change-small {
    font-size: 80%;

    input {
      width: 30px;
    }

    td {
      padding: 2px 4px!important;

      &.second-item {
        padding: 1px!important;
      }

    }

  }

}


.uib-datepicker-popup {
  border-radius: 0px;
  border: 1px solid $grey;
  top: 33px!important;
  background-color: white;

  .uib-datepicker {

    button.active {
      background-color: $corporateColor;
      border: 0px;
      color: white;

      span {
        color: white;
      }
    }
  }

  button {
    background-color: transparent;
    border: 0px;

    &.btn-info, &.btn-danger, &.btn-success {
      background-color: #f4f4f4;
      border: 1px dashed #ddd;
      color: black;
    }

  }
  .btn-group>.btn:last-child:not(:first-child) {
    border-left: 1px solid white;

  }
}

.btn-danger:focus {
  background-color: $corporateColor!important;
}

.popover.ng-isolate-scope {
  visibility: visible;
  display: block;
}

.alert, .callout {
  position: relative;
}

.alert.alert-super-danger {
  background-color: $dangerColor!important;
  color: white;
}

.label.label-super-danger {
  background-color: $dangerColor!important;
  color: white;
}
.label.label-draft {
  background-color: black!important;
  color: white;
}

.callout.callout-super-danger {
  background-color: $dangerColor!important;
  color: white;
  border-left: 5px #c23321 solid;
}

.popover {
  max-width: 450px!important;
}

.bg-gray-with-border {
  background-color: #f9f9f9;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  margin: 10px;
  padding: 15px;
}

.uib-datepicker:focus,
.uib-daypicker:focus,
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  box-shadow: none;
  outline: 0 none;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

[ng-click] {
  cursor: pointer;
}

input, select {
  &.ng-invalid.ng-touched {
    background-color: #fad4d0;
    border-color: $corporateColor;
  }
}

.ng-invalid.ng-submitted {
  input, select {
    &.ng-invalid {
      background-color: #fad4d0;
      border-color: $corporateColor;

    }
  }
}

input, select {
  &.ng-valid.ng-touched {
    // background-color: #abfab3;
  }
}

.modal-content {
  background-color: inherit;

}

.btn-fixed {
  text-align: left;
  padding: 6px 16px;
  i {
    margin-right: 10px;
  }
}

.markdown-area {
  img {
    max-width: 100%;
  }
}

.margin-top-5 {
  margin-top: 5px!important;
}

.margin-top-10 {
  margin-top: 10px!important;
}

.margin-top-15 {
  margin-top: 15px!important;
}

.margin-top-20 {
  margin-top: 20px!important;
}

.margin-top-25 {
  margin-top: 25px!important;
}

.margin-bottom-5 {
  margin-bottom: 5px!important;
}

.margin-bottom-10 {
  margin-bottom: 10px!important;
}

.margin-bottom-15 {
  margin-bottom: 15px!important;
}

.margin-bottom-20 {
  margin-bottom: 20px!important;
}

.margin-bottom-25 {
  margin-bottom: 25px!important;
}

.margin-left-5 {
  margin-left: 5px!important;
}

.margin-left-10 {
  margin-left: 10px!important;
}

.margin-left-15 {
  margin-left: 15px!important;
}

.margin-right-5 {
  margin-right: 5px!important;
}

.margin-right-10 {
  margin-right: 10px!important;
}

.margin-right-15 {
  margin-right: 15px!important;
}

.width-150 {
  width: 150px!important;
}

.width-175 {
  width: 175px!important;
}

.width-200 {
  width: 200px!important;
}

.width-225 {
  width: 225px!important;
}

.width-250 {
  width: 250px!important;
}

.font-size-200 {
  font-size:200%!important;
}

.font-size-195 {
  font-size:195%!important;
}

.font-size-190 {
  font-size:190%!important;
}

.font-size-185 {
  font-size:185%!important;
}

.font-size-180 {
  font-size:180%!important;
}

.font-size-175 {
  font-size:175%!important;
}

.font-size-170 {
  font-size:170%!important;
}

.font-size-165 {
  font-size:165%!important;
}

.font-size-160 {
  font-size:160%!important;
}

.font-size-155 {
  font-size:155%!important;
}

.font-size-150 {
  font-size:150%!important;
}

.font-size-145 {
  font-size:145%!important;
}

.font-size-140 {
  font-size:140%!important;
}

.font-size-135 {
  font-size:135%!important;
}

.font-size-130 {
  font-size:130%!important;
}

.font-size-125 {
  font-size:125%!important;
}

.font-size-120 {
  font-size:120%!important;
}

.font-size-115 {
  font-size:115%!important;
}

.font-size-110 {
  font-size:110%!important;
}

.font-size-105 {
  font-size:105%!important;
}

.font-size-95 {
  font-size:95%!important;
}

.font-size-90 {
  font-size:90%!important;
}

.font-size-85 {
  font-size:85%!important;
}

.font-size-80 {
  font-size:80%!important;
}

.font-size-75 {
  font-size:75%!important;
}

.font-size-70 {
  font-size:70%!important;
}

.bg-white {
  background-color: white;
}

.bg-text {
  padding: 3px 7px;
  color: white;
  border-radius: 2px;
}

.bg-text-danger {
  background: $corporateColor;
}

.bg-text-warning {
  background: #f39c12;
}

.bg-text-super-danger {
  background: $dangerColor;
}
.bg-text-success {
  background: #00a65a;
}

.bg-text-default {
  background: #f4f4f4;
}

.btn-left {
  text-align: left;
}

.site-wrapper {
  .box, .box-footer {
    background-color: #fafafa;
  }
}

.btn-danger.active {
  background-color: $corporateColor;
}

.btn-super-danger {
  background: $dangerColor;
}

.text-super-danger {
  color: $dangerColor;
}

.typeahead-search {
  .dropdown-menu {
    max-height: 300px;
    overflow-y: auto;

    &> .active > a {
      background-color: $corporateColor;
    }
  }
}

.direct-chat-messages {
  height: auto;

  .direct-chat-msg {
    &.right {
      .direct-chat-text {
        background-color: #feeee3;
      }
    }

    .direct-chat-text {
      background-color: #f3fef4;
    }
  }

}

.text-through {
  text-decoration: line-through;
}

.modal-mg {
  width: 1200px;
}

.money-col {
  white-space: nowrap;
}

.dashed {
  border-bottom: dashed;
  border-width: 1px;
}

.rounded-block {
  border: 1px solid $lightGrey;
  border-radius: 5px;
  padding: 5px;
}

.grey-border-left {
  border-left: 3px solid $lightGrey;
}

.red-border-left {
  border-left: 3px solid $dangerColor;
}

.alert-panel {
  position: fixed;
  right: 10px;
  top: 20px;
  width: 350px;
  z-index: 10001;
  opacity: 0.9;
  .callout {
    position: relative;
    margin-bottom: 5px;
    padding: 10px 20px 10px 10px;
    .fa-remove {
      right: 15px;
      position: absolute;
    }
  }
}

div[nv-file-drop] {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  background: #fcfff6;
  text-align: center;
  border: 1px dashed #679bff;
  font-size: 130%;
  vertical-align: middle;
  color: #343434;
  border-radius: 6px;
}

.select2 {
  .select2-selection {
    height: 34px;
    border: 1px solid #cdcdcd;

  }
}

.select2-results {
  li {
    padding: 2px 6px;
  }
}

.select2-container--default .select2-results>.select2-results__options {
  max-height: 300px!important;
}

.select2.select2-container {
  width: 100%!important;
}

.uib-datepicker:focus,
.uib-daypicker:focus,
button:focus,
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  box-shadow: none!important;
  outline: 0 none!important;
}

.removed-bg {
  background: url('/bundles/ics/images/main/remove-backgroud.png');
}

.form-group {
  min-height: 28px;
}